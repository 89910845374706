import { CSSProperties } from 'react';

export const TextElement = (props: {
  content: string | null;
  className?: string;
  style?: CSSProperties;
}) => {
  const { content, className = '', style = {} } = props;
  if (!content) {
    return null;
  } else {
    return (
      <div
        className={className}
        style={style}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }
};

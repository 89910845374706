import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import {
  ITemplateFieldFile,
  ITemplateFieldString,
  ITemplateFieldVideoContent
} from '@gemini/shared/services/content/next-mantlecms';
import { useTranslations } from '@gemini/shared/services/content/translations';
import { Icon } from '@gemini/shared/ui/molecules/icon';
import { SmartImage } from '@gemini/shared/ui/molecules/smart-image';
import {
  SmartSource,
  SmartVideo
} from '@gemini/shared/ui/molecules/smart-video';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import { getImageSizeFromUrl } from '@gemini/shared/ui/utils/image';
import styles from '../multiuse-hero-banner.module.scss';
import {
  IHeroBannerData,
  IVideoAutoplay,
  VIDEO_AUTOPLAY
} from '../MultiUseHeroBanner';

function getPaddingTop(naturalWidth: number, naturalHeight: number): string {
  return `calc(100% / (${naturalWidth} / ${naturalHeight}))`;
}

const DEFAULT_PADDING_TOP = getPaddingTop(1366, 499);

const setMediaStyles = (
  isMobile: boolean,
  videoContent: IHeroBannerData['videoContent']
) => {
  return {
    top: isMobile
      ? videoContent.data.videoContentTopMob ?? '0'
      : videoContent.data.videoContentTopPc ?? '0',
    left: isMobile
      ? videoContent.data.videoContentLeftMob ?? '0'
      : videoContent.data.videoContentLeftPc ?? '0'
  };
};

const VideoElement = (props: {
  source: string;
  className?: string;
  videoAutoplay?: boolean;
  onLoadVideo?: (value: boolean) => void;
}) => {
  const { source, className = '', videoAutoplay = false, onLoadVideo } = props;

  return (
    <SmartVideo
      className={className}
      autoPlay={videoAutoplay}
      muted
      loop
      playsInline
      src={source}
      onLoadedData={() => {
        onLoadVideo ? onLoadVideo(false) : null;
      }}
    >
      <SmartSource src={source} type="video/mp4" />
    </SmartVideo>
  );
};

const cl = (className: string) => styles[className] ?? '';

interface IMediaWrapper {
  isDesktop?: boolean;
  bgLarge: ITemplateFieldFile;
  bgSmall: ITemplateFieldFile;
  imageAltText?: ITemplateFieldString;
  loopingVidDesktop: ITemplateFieldString;
  loopingVidMob: ITemplateFieldString;
  onSetActivePlayer: () => void;
  videoAutoplay?: IVideoAutoplay;
  videoContent: ITemplateFieldVideoContent;
}

export const MediaWrapper = ({
  bgLarge,
  bgSmall,
  imageAltText,
  loopingVidDesktop,
  loopingVidMob,
  onSetActivePlayer,
  videoAutoplay,
  videoContent,
  isDesktop
}: IMediaWrapper) => {
  const isMobile = !isDesktop;
  const { isMobile: isXl } = useBreakpoint('block xl:hidden');
  const {
    elc_general: { play: playLabel }
  } = useTranslations();

  const [computedPaddingTop, setComputedPaddingTop] = useState('');
  const [isImageShow, setImageShow] = useState<boolean>(true);

  const inferredPaddingTopBig = useMemo(() => {
    const info = getImageSizeFromUrl(`${bgLarge.data}`);
    if (info) {
      const { width, height } = info;

      return getPaddingTop(width, height);
    }

    return '';
  }, [bgLarge.data]);

  const inferredPaddingTopSmall = useMemo(() => {
    const info = getImageSizeFromUrl(`${bgSmall.data}`);
    if (info) {
      const { width, height } = info;

      return getPaddingTop(width, height);
    }

    return '';
  }, [bgSmall.data, bgSmall.data]);

  const paddingTop = useMemo(() => {
    return (
      computedPaddingTop ||
      (isMobile
        ? inferredPaddingTopSmall || inferredPaddingTopBig
        : inferredPaddingTopBig || inferredPaddingTopSmall) ||
      DEFAULT_PADDING_TOP
    );
  }, [
    isDesktop,
    isMobile,
    computedPaddingTop,
    inferredPaddingTopSmall,
    inferredPaddingTopBig
  ]);

  const videoLoopSrc = isMobile ? loopingVidMob.data : loopingVidDesktop.data;

  useEffect(() => {
    setImageShow(true);
  }, [bgLarge.data, bgSmall.data, videoLoopSrc]);

  const iconSize = isMobile ? '21.094' : isXl ? '6.594' : '90';
  const iconUnit = isMobile ? 'vw' : isXl ? 'vw' : 'px';
  const videoCtaColorName =
    videoContent?.data?.videoContentCtaColor === 'dark'
      ? 'VideoCircleDark'
      : 'VideoCircleLight';

  const playButtonEnabled =
    videoContent.data.videoContentDisplay !== 'no_video';

  const videoAutoplayValue = videoAutoplay
    ? videoAutoplay.data
    : VIDEO_AUTOPLAY.autoplay;
  const videoAutoplayEnabled = videoAutoplayValue === VIDEO_AUTOPLAY.autoplay;

  const mediaData = isMobile ? bgSmall.data || bgLarge.data : bgLarge.data;

  const isLoopingVideo = Boolean(videoLoopSrc);

  const imageEffectClass = isLoopingVideo ? cl('fadeElemOut') : '';

  return (
    <div className="media-wrapper" style={{ paddingTop }}>
      {Boolean(videoContent.data.videoContentYoutubeLink) && playButtonEnabled && (
        <div
          className="media-wrapper__content"
          onClick={() => onSetActivePlayer()}
          style={setMediaStyles(isMobile, videoContent) || {}}
        >
          <Icon
            name={videoCtaColorName}
            label={playLabel}
            width={iconSize}
            height={iconSize}
            unit={iconUnit}
            fill="white"
          />
        </div>
      )}
      {isLoopingVideo && (
        <div className="media-wrapper__autoplay_video">
          <VideoElement
            onLoadVideo={setImageShow}
            videoAutoplay={videoAutoplayEnabled || isLoopingVideo}
            source={videoLoopSrc}
            className={cn(
              'media-wrapper__looping_vidContent',
              cl('fadeElemIn')
            )}
          />
        </div>
      )}
      {Boolean(mediaData) && isImageShow && (
        <div className={cn(imageEffectClass, isLoopingVideo && 'hidden')}>
          <SmartImage
            alt={imageAltText?.data || ''}
            src={mediaData}
            layout="fill"
            onLoadingComplete={(image) => {
              const { naturalWidth, naturalHeight } = image;
              if (naturalWidth && naturalHeight) {
                setComputedPaddingTop(
                  getPaddingTop(naturalWidth, naturalHeight)
                );
              }
            }}
            objectFit="cover"
            objectPosition="center"
            priority={true}
          />
        </div>
      )}
    </div>
  );
};

import { CSSProperties } from 'react';

const CSS_COLOR_REGEX = /^(#|rgba|rgb|hsl|hsla|hwb|lab|lch|oklab|oklch|color)/i;

const isCssColor = (color: string) => CSS_COLOR_REGEX.test(color.trim());

export function getTextAlignClass(alignment: string | null): string {
  return alignment ? `align--${alignment}` : 'align';
}

export function getFontFamilyClass(font: string | null): string {
  return font ? `font--${font}` : 'font';
}

export function getCustomSize(
  size: string,
  isMobile: boolean,
  isDesktop: boolean,
  forceToVW: boolean
) {
  if (!forceToVW && isDesktop) {
    return size; // 1366px +
  }

  const newSize = size.replace(/[^0-9]/g, '');
  // Calculate the vw value it should be at 1366px.
  // 1.000733 brings it closest to the original font size.
  // 1366 is the breakpoint.
  // Calculate the vw value it should be at 640px.
  // 1.198438 brings it closest to the original font size.
  // 767 is the breakpoint.

  return `${
    isMobile
      ? ((100 / 767) * Number(newSize) * 1.198438).toFixed(6)
      : ((100 / 1366) * Number(newSize) * 1.000733).toFixed(6)
  }vw`;
}

export function getSizeStyle(
  size: string | null,
  custom: string | null = null,
  isFontCreditSize = false,
  isMobile: boolean,
  isDesktop: boolean,
  forceToVW = false
): CSSProperties {
  const calcSize =
    size === 'custom_size'
      ? custom
        ? getCustomSize(custom, isMobile, isDesktop, forceToVW)
        : undefined
      : isFontCreditSize
      ? '15px'
      : '80px';

  return calcSize ? { fontSize: calcSize } : {};
}

export function getExploreButtonColorClass(color: string | null): string {
  return color ? `explore-button-color--${color}` : 'explore-button-color';
}

export function getButtonColorClass(color: string | null): string {
  return color ? `button-color--${color}` : 'button-color';
}

export function getColorClass(color: string | null): string {
  return color && !isCssColor(color)
    ? `header-color--${color}`
    : 'header-color';
}

export function getColorStyle(
  color: string | null,
  custom: string | null = null
): CSSProperties {
  const calcColor = color
    ? color === 'custom'
      ? custom ?? undefined
      : color
    : '#040a2b';

  return calcColor && isCssColor(calcColor) ? { color: calcColor } : {};
}

export function getMarginStyle(size: string | null): CSSProperties {
  return size ? { marginLeft: size } : {};
}

export enum TEMPLATES {
  TEMPLATE1 = 'MPP-Template-1',
  TEMPLATE2 = 'MPP-Template-2',
  TEMPLATE3 = 'MPP-Template-3',
  TEMPLATE4 = 'MPP-Template-4'
}

export enum HOMEPAGE_TEMPLATES {
  TEMPLATE1 = 'Homepage-Template-1',
  TEMPLATE2LEFT = 'Homepage-Template-2-Left',
  TEMPLATE2RIGHT = 'Homepage-Template-2-Right',
  TEMPLATE3RIGHT = 'Homepage-Template-3-Right',
  TEMPLATE4LEFT = 'Homepage-Template-4-Left',
  TEMPLATE4RIGHT = 'Homepage-Template-4-Right',
  TEMPLATE5 = 'Homepage-Template-5',
  TEMPLATE6LEFT = 'Homepage-Template-6-Left',
  TEMPLATE6RIGHT = 'Homepage-Template-6-Right',
  TEMPLATEESTEESTORIES = 'Homepage-Estee-Stories',
  TEMPLATEBEAUTYLEFT = 'Homepage-3-Minute-Beauty-Left',
  TEMPLATEBEAUTYRIGHT = 'Homepage-3-Minute-Beauty-Right'
}

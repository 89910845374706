import cn from 'classnames';
import { useMemo } from 'react';
import { ITemplateFieldHeadlineContentGroup } from '@gemini/shared/services/content/next-mantlecms';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import styles from '../multiuse-hero-banner.module.scss';
import {
  getColorClass,
  getColorStyle,
  getFontFamilyClass,
  getMarginStyle,
  getSizeStyle,
  getTextAlignClass
} from '../utils/styleUtils';
import { TextElement } from './shared/TextElement';

const cl = (className: string) => styles[className] ?? '';

interface IHeadlineProps {
  template?: string;
  show?: boolean;
  data: ITemplateFieldHeadlineContentGroup;
  isHome?: boolean;
  isDesktop?: boolean;
}

export const Headline = ({
  data,
  show = true,
  template = '',
  isHome = false,
  isDesktop = false
}: IHeadlineProps) => {
  const {
    header,
    headerFontFamily,
    headlineContentAlignment,
    headerAlignment,
    headerColor,
    headerColorCustom,
    headerSize,
    headerSizeCustom
  } = data.data;

  if (!show || !header) {
    return null;
  }

  const { isDesktop: isXL } = useBreakpoint('block xl:hidden');
  const isMobile = !isDesktop;

  const sizeStyle = useMemo(() => {
    return getSizeStyle(
      headerSize,
      headerSizeCustom,
      false,
      isMobile,
      isDesktop && isXL
    );
  }, [headerSize, headerSizeCustom, isMobile, isXL]);

  const colorStyle = useMemo(() => {
    return getColorStyle(headerColor, headerColorCustom);
  }, [headerColor, headerColorCustom]);

  const marginStyle = useMemo(() => {
    return getMarginStyle(headerAlignment);
  }, [headerAlignment]);

  return (
    <TextElement
      content={header}
      className={cn(
        'headline-content__headline',
        `headline-content__headline--${template}`,
        cl(getFontFamilyClass(headerFontFamily)),
        cl(getTextAlignClass(headlineContentAlignment)),
        cl(getColorClass(headerColor)),
        headerColor
      )}
      style={{
        ...colorStyle,
        ...sizeStyle,
        ...marginStyle
      }}
    />
  );
};

import cn from 'classnames';
import { CSSProperties } from 'react';
import { ITemplateFieldHeadlineContentGroup } from '@gemini/shared/services/content/next-mantlecms';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';
import { TEXT_WRAPPER } from '@gemini/shared/utils/testing';
import { TEMPLATES } from '../constants/constants';
import styles from '../multiuse-hero-banner.module.scss';
import {
  getButtonColorClass,
  getColorClass,
  getColorStyle,
  getExploreButtonColorClass
} from '../utils/styleUtils';
import { Credits } from './Credits';
import { Headline } from './Headline';
import { TextElement } from './shared/TextElement';

const cl = (className: string) => styles[className] ?? '';

interface IHeadlineWrapper {
  data: ITemplateFieldHeadlineContentGroup;
  template?: string;
  className?: string;
  isHome?: boolean;
  showCredits?: boolean;
  isDesktop?: boolean;
}

export const HeadlineWrapper = ({
  className = '',
  template,
  data,
  isHome = false,
  showCredits = true,
  isDesktop
}: IHeadlineWrapper) => {
  const isMobile = !isDesktop;
  const content = data.data;
  const headlineStyles: CSSProperties = {
    top: content.headlineContentTop ? content.headlineContentTop : '0',
    left: content.headlineContentLeft ? content.headlineContentLeft : '0',
    textAlign: content.headlineContentAlignment
      ? content.headlineContentAlignment
      : 'center'
  };

  const isTemplateFour = template === TEMPLATES.TEMPLATE4;

  return (
    <div
      data-testid={TEXT_WRAPPER}
      className={cn(
        'headline-content_group',
        'headline-bannerTextWrapper',
        `headline-content_group--${template}`,
        className
      )}
      style={headlineStyles}
    >
      <TextElement
        content={content.eyebrow}
        className={cn(
          'headline-content__eyebrow',
          cl(getColorClass(content.eyebrowColor)),
          content.eyebrowColor
        )}
        style={{
          ...getColorStyle(content.eyebrowColor, content.eyebrowColorCustom)
        }}
      />
      {content.logo === 'logo' && <div className="headline-content__logo" />}
      <Headline
        show={!(isMobile && isTemplateFour)}
        data={data}
        isHome={isHome}
        isDesktop={isDesktop}
      />
      <div className="headline-content__sub-content">
        {(content.subHeader || content.subCopy) && (
          <div
            className={cn(
              'headline-content__subtext',
              'body_copy',
              cl(getColorClass(content.subColor)),
              content.subColor
            )}
            style={{
              ...getColorStyle(content.subColor, content.subColorCustom)
            }}
          >
            <TextElement
              content={content.subHeader}
              className="headline-content__subheader"
            />
            <TextElement
              content={content.subCopy}
              className="headline-content__subcopy"
            />
          </div>
        )}
        <div className="headline-content__bottom-content">
          {content.buttonCtaUrl && content.buttonCtaText && (
            <div className="headline-content__cta_wrapper">
              <SmartLink
                href={content.buttonCtaUrl}
                classes={cn(
                  'headline-content__cta_button',
                  'button',
                  'cta',
                  'cta__button',
                  cl(getButtonColorClass(content.buttonCtaStyle)),
                  `button_style_${content.buttonCtaStyle}`
                )}
              >
                {content.buttonCtaText}
              </SmartLink>
            </div>
          )}

          {content.linkExploreUrl && content.linkExplore && (
            <div
              className={cn(
                'headline-content__explore-link',
                cl(getColorClass(content.exploreColor)),
                content.exploreColor
              )}
              style={{
                ...getColorStyle(
                  content.exploreColor,
                  content.exploreColorCustom
                )
              }}
            >
              <SmartLink
                href={content.linkExploreUrl}
                classes={cn(
                  'headline-content__explore-link__button',
                  cl(getExploreButtonColorClass(content.linkExploreUrlStyle))
                )}
                dangerouslySetInnerHTML={{ __html: content.linkExplore }}
              />
            </div>
          )}

          <TextElement
            content={content.hashtag}
            className={cn(
              'headline-content__hashtag',
              cl(getColorClass(content.hashtagColor)),
              content.hashtagColor,
              'body_copy'
            )}
            style={{
              ...getColorStyle(content.hashtagColor, content.hashtagColorCustom)
            }}
          />
          <TextElement
            content={content.disclaimer}
            className={cn(
              'headline-content__disclaimer',
              cl(getColorClass(content.disclaimerColor)),
              content.disclaimerColor,
              'body_copy'
            )}
            style={{
              ...getColorStyle(
                content.disclaimerColor,
                content.disclaimerColorCustom
              )
            }}
          />
        </div>
      </div>
    </div>
  );
};

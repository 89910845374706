import cn from 'classnames';
import { CSSProperties, useMemo } from 'react';
import { ITemplateFieldHeadlineContentGroup } from '@gemini/shared/services/content/next-mantlecms';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import styles from '../multiuse-hero-banner.module.scss';
import {
  getColorClass,
  getColorStyle,
  getSizeStyle,
  getTextAlignClass
} from '../utils/styleUtils';
import { TextElement } from './shared/TextElement';

interface ICredits {
  data: ITemplateFieldHeadlineContentGroup;
  template?: string;
  show?: boolean;
  isDesktop?: boolean;
}

const cl = (className: string) => styles[className] ?? '';

export const Credits = ({
  data,
  show = true,
  template = '',
  isDesktop = false
}: ICredits) => {
  const {
    creditContentTop,
    creditContentLeft,
    credit,
    creditContentAlignment,
    creditColor,
    creditColorCustom,
    creditSize,
    creditSizeCustom,
    creditPosCustomLeft,
    creditPosCustomTop
  } = data.data;

  if (!show || !credit) {
    return null;
  }

  const isMobile = !isDesktop;
  const { isDesktop: isLg } = useBreakpoint('block lg:hidden');

  const creditStyles: CSSProperties = {
    top: creditContentTop || creditPosCustomTop || '0',
    left: creditContentLeft || creditPosCustomLeft || '0'
  };

  const sizeStyle = useMemo(() => {
    return getSizeStyle(
      creditSize,
      creditSizeCustom,
      true,
      isMobile,
      isDesktop && isLg
    );
  }, [creditSize, creditSizeCustom, isMobile, isLg]);

  const colorStyle = useMemo(() => {
    return getColorStyle(creditColor, creditColorCustom);
  }, [creditColor, creditColorCustom]);

  return (
    <TextElement
      content={credit}
      className={cn(
        'headline-content__credit',
        `headline-content__credit--${template}`,
        cl(getTextAlignClass(creditContentAlignment)),
        cl(getColorClass(creditColor))
      )}
      style={{
        ...colorStyle,
        ...sizeStyle,
        ...creditStyles
      }}
    />
  );
};

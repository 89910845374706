import cn from 'classnames';
import { useContext, useEffect, useRef, useState } from 'react';
import { PageFormatterContext } from '@gemini/brand/el/ui/templates/page-formatter';
import {
  ITemplateFieldCheckbox,
  ITemplateFieldFile,
  ITemplateFieldHeadlineContentGroup,
  ITemplateFieldRadio,
  ITemplateFieldSelect,
  ITemplateFieldString,
  ITemplateFieldVideoContent
} from '@gemini/shared/services/content/next-mantlecms';
import { SmartLink } from '@gemini/shared/ui/molecules/smart-link';
import { useScrollItem } from '@gemini/shared/ui/organisms/page-scroller';
import { useBreakpoint, useInViewport } from '@gemini/shared/ui/utils/hooks';
import { HERO_BANNER } from '@gemini/shared/utils/testing';
import { Credits } from './components/Credits';
import { Headline } from './components/Headline';
import { HeadlineWrapper } from './components/HeadlineWrapper';
import { MediaWrapper } from './components/MediaWrapper';
import { YoutubeVideoWrapper } from './components/YoutubeVideoWrapper';
import { HOMEPAGE_TEMPLATES, TEMPLATES } from './constants/constants';
import styles from './multiuse-hero-banner.module.scss';

export enum VIDEO_AUTOPLAY {
  'no_autoplay' = 'no_autoplay',
  'autoplay' = 'autoplay'
}

export interface IVideoAutoplay extends ITemplateFieldRadio {
  data: keyof typeof VIDEO_AUTOPLAY;
}

export interface IHeroBannerData {
  bgLarge: ITemplateFieldFile;
  bgSmall: ITemplateFieldFile;
  brandSection: ITemplateFieldSelect;
  disableLazyload: ITemplateFieldCheckbox;
  headlineContent: ITemplateFieldHeadlineContentGroup;
  imageAltText: ITemplateFieldString;
  loopingVidDesktop: ITemplateFieldString;
  loopingVidMob: ITemplateFieldString;
  loopingVidVolColor: ITemplateFieldRadio;
  loopingVidVolHideShow: ITemplateFieldRadio;
  marginClass?: ITemplateFieldCheckbox;
  moduleLink: ITemplateFieldString;
  templateLabelname: ITemplateFieldString;
  templateStyle: ITemplateFieldSelect;
  videoAutoplay?: IVideoAutoplay;
  videoContent: ITemplateFieldVideoContent;
}

export interface IMultiUseHeroBannerProps extends IHeroBannerData {
  isHome?: boolean;
  isDesktop?: boolean;
  classes?: string;
  index?: number;
}

const cl = (className: string) => styles[className] ?? '';

/*
  DRUPAL MUSTACHES COMBINATION:
  esteelauder\modules\custom\esteelauder_common\templates\styleguide_templates\styleguide_multi_use_mpp_v1\styleguide_multi_use_mpp_v1.mustache
  esteelauder\modules\custom\esteelauder_common\templates\styleguide_templates\styleguide_multi_use_homepage_v1\styleguide_multi_use_homepage_v1.mustache
  MAPPING: esteelauder\modules\custom\esteelauder_common\includes\templates.inc
*/

const buildStyleClass = (base: string, style: ITemplateFieldSelect) => {
  return style.data ? `${base}_${style.data.toLowerCase()}` : '';
};

const buildBrandClass = (base: string, style: ITemplateFieldSelect) => {
  return style.data ? `${base}_${style.data}` : '';
};

export function MultiUseHeroBanner({
  classes,
  isHome,
  index,
  isDesktop,
  ...props
}: IMultiUseHeroBannerProps) {
  const [activePlayer, setActivePlayer] = useState<boolean>(false);

  const { isMobile } = useBreakpoint('block md:hidden');

  useEffect(() => {
    setActivePlayer(false);
  }, [isDesktop]);

  const onSetActivePlayer = () => setActivePlayer(!activePlayer);

  const {
    bgLarge,
    bgSmall,
    headlineContent,
    imageAltText,
    loopingVidDesktop,
    loopingVidMob,
    moduleLink,
    templateStyle,
    brandSection,
    videoAutoplay,
    videoContent,
    marginClass
  } = props;

  const bannerLink = headlineContent.data.buttonCtaUrl || moduleLink.data;

  const bannerLinkTitle = headlineContent.data.buttonCtaText || moduleLink.data;

  const isTemplateFour = templateStyle.data === TEMPLATES.TEMPLATE4;
  const isTemplateThree =
    templateStyle.data === HOMEPAGE_TEMPLATES.TEMPLATE3RIGHT;
  const isTemplateOne = templateStyle.data === HOMEPAGE_TEMPLATES.TEMPLATE1;

  const showCredits =
    (isMobile && isTemplateFour) ||
    (isDesktop && (isTemplateThree || isTemplateOne));

  const scrollItem = useScrollItem();

  const targetRef = useRef<HTMLDivElement>(null);

  const { inViewport: isVisible } = useInViewport(
    targetRef,
    { rootMargin: '-100px' },
    { disconnectOnLeave: false }
  );

  const { setActiveIndex, addIndex } = useContext(PageFormatterContext);

  useEffect(() => {
    if (index !== undefined) {
      addIndex?.(index);
    }
  }, [index]);

  useEffect(() => {
    if (index !== undefined && isVisible) {
      setActiveIndex?.(index);
    }
  }, [isVisible, index]);

  return (
    <div
      data-testid={HERO_BANNER}
      className={cn(
        cl('multiuse-hero-banner'),
        classes || '',
        'multi_use_homepage_v1',
        isHome && index === 0 && marginClass
          ? `homepage_module_wrapper_${marginClass.data}`
          : '' // Offset to keep the banner behind the header
      )}
      ref={isHome && isDesktop ? scrollItem : null}
    >
      <div ref={targetRef}>
        <div className="wrapper">
          <div
            className={cn(
              'module_block',
              buildStyleClass('module_block', templateStyle),
              buildBrandClass('module_block', brandSection)
            )}
          >
            {Boolean(bannerLink) && (
              <SmartLink
                classes="module_block__hero_link"
                href={bannerLink}
                ariaLabel={bannerLinkTitle}
              />
            )}
            <div
              className={cn(
                'module_block__container',
                buildStyleClass('module_block__container', templateStyle)
              )}
            >
              <div className="module_block__hero">
                <Headline
                  show={isMobile && isTemplateFour}
                  template={templateStyle.data}
                  data={headlineContent}
                  isHome={isHome}
                  isDesktop={isDesktop}
                />
                <MediaWrapper
                  bgLarge={bgLarge}
                  bgSmall={bgSmall}
                  isDesktop={isDesktop}
                  imageAltText={imageAltText}
                  loopingVidDesktop={loopingVidDesktop}
                  loopingVidMob={loopingVidMob}
                  onSetActivePlayer={onSetActivePlayer}
                  videoAutoplay={videoAutoplay}
                  videoContent={videoContent}
                />
              </div>
              <div className="module_block__content">
                <Credits
                  show={showCredits}
                  template={templateStyle.data}
                  data={headlineContent}
                  isDesktop={isDesktop}
                />
                <HeadlineWrapper
                  data={headlineContent}
                  template={templateStyle.data}
                  isHome={isHome}
                  showCredits={!showCredits}
                  isDesktop={isDesktop}
                />
                <Credits
                  show={!showCredits}
                  data={headlineContent}
                  isDesktop={isDesktop}
                />
              </div>
            </div>
          </div>
          {activePlayer && (
            <YoutubeVideoWrapper
              content={videoContent.data}
              onSetActivePlayer={onSetActivePlayer}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MultiUseHeroBanner;

import { ITemplateFieldVideoContent } from '@gemini/shared/services/content/next-mantlecms';
import { YouTubeFrame } from '@gemini/shared/ui/atoms/youtube-frame';
import { Icon } from '@gemini/shared/ui/molecules/icon';
import { useBreakpoint } from '@gemini/shared/ui/utils/hooks';
import { VIDEO_WRAPPER } from '@gemini/shared/utils/testing';

interface IYoutubeVideoContainerProps {
  source: string | null;
}

const YoutubeVideoContainer = ({ source }: IYoutubeVideoContainerProps) =>
  source ? (
    <div className="video__container">
      <YouTubeFrame
        source={source}
        width="100%"
        height="100%"
        className="video__container--video"
        lazy
        autoplay
      />
    </div>
  ) : null;

export const YoutubeVideoWrapper = (props: {
  content: ITemplateFieldVideoContent['data'];
  className?: string;
  onSetActivePlayer(): void;
}) => {
  const {
    className = '',
    content: { videoContentYoutubeLink },
    onSetActivePlayer
  } = props;

  const { isMobile } = useBreakpoint('block md:hidden');

  return (
    <div data-testid={VIDEO_WRAPPER} className={`video ${className}`}>
      <div
        className="video__container--button"
        onClick={() => onSetActivePlayer()}
      >
        <Icon
          name="ClosePlayerLight"
          label="close video"
          width={isMobile ? '6.25' : '30'}
          height={isMobile ? '6.25' : '30'}
          unit={isMobile ? 'vw' : 'px'}
        />
      </div>
      <YoutubeVideoContainer source={videoContentYoutubeLink} />
    </div>
  );
};
